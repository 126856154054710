
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SiteMap',
  
  created(){
    window.location.href = "/sitemap.xml";
  }
});
